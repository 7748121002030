import { createBrowserRouter } from 'react-router-dom';
import Home from '../pages/Home';
import ContactUs from '../pages/ContactUs';
import { RefundPolicy } from '../pages/RefundPolicy';
import { Legal } from '../pages/Legal';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { TermsOfService } from '../pages/TermsOfService';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/contact',
        element: <ContactUs />,
    },
    {
        path: '/legal',
        element: <Legal />,
    },
    {
        path: '/legal/terms-of-service',
        element: <TermsOfService />,
    },
    {
        path: '/legal/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/legal/refund-policy',
        element: <RefundPolicy />,
    },
]);

export default router;