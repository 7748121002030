import { useNavigate } from 'react-router';
import Header from '../components/Header';
import Footer from '../components/Footer';

function GrayCard({
    children,
    onClick,
}: {
    children?: React.ReactNode;
    onClick?: () => void;
}) {
    return <div
        className='cursor-pointer rounded-md border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 flex justify-start p-4 overflow-y-auto'
        style={{ maxHeight: '54vh' }}
        onClick={onClick}
    >
        {children}
    </div>;
}

export function Legal() {
    const navigate = useNavigate();
    return <div className='main'>
        <Header active='legal' />
        <div className='h-12'></div>

        <div className='p-16'>
            <GrayCard onClick={() => navigate('/legal/terms-of-service')}>
                <span className='text-lg'>Terms of Service</span>
            </GrayCard>

            <div className='h-8' />

            <GrayCard onClick={() => navigate('/legal/privacy-policy')}>
                <span className='text-lg'>Privacy Policy</span>
            </GrayCard>

            <div className='h-8' />

            <GrayCard onClick={() => navigate('/legal/refund-policy')}>
                <span className='text-lg'>Refund Policy</span>
            </GrayCard>
        </div>

        <Footer bottom />
    </div>;
}