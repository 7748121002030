function Footer({
    bottom = false,
}: {
    bottom?: boolean;
}) {
    return <div className='h-24 bg-black w-full text-center text-[#969696]' style={{
        position: bottom ? 'absolute' : 'relative',
        bottom: 0,
        left: 0,
        right: 0,
    }}>
        <div className='pt-10'>
            Copyright © 2023 Nix Software Enterprise - All Rights Reserved.
        </div>
    </div>;
}

export default Footer;