import Header from '../components/Header';
import Footer from '../components/Footer';

export function PrivacyPolicy() {
    return <div className='main'>
        <Header active='legal' />
        <div className='h-12'></div>
        <div className='p-16'>
            <div className='font-bold text-2xl'>Privacy Policy</div>
            <div className='text-slate-400'>Please read the below policy carefully</div>

            <div className='h-4'></div>
            <div
                className='rounded-md border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 flex justify-start p-4 overflow-y-auto'
                style={{ maxHeight: '58vh' }}
            >
                <div className='text-justify'>
                    <div className='text-lg font-semibold'>Privacy Policy</div>
                    <div>Last updated: 17<sup>th</sup> February 2025</div>
                    <br />
                    <div className='text-lg font-semibold'>Table Of Content</div>
                    <div><a href="#1">1. Introduction</a></div>
                    <div><a href="#2">2. Interpretation</a></div>
                    <div><a href="#3">3. Information We Collect</a></div>
                    <div><a href="#4">4. How We Use Your Information</a></div>
                    <div><a href="#5">5. Disclosure Of Your Information</a></div>
                    <div><a href="#6">6. Your Rights</a></div>
                    <div><a href="#7">7. Third Party Links</a></div>
                    <div><a href="#8">8. Use Of Cookies</a></div>
                    <div><a href="#9">9. Security</a></div>
                    <div><a href="#10">10. Data Retention</a></div>
                    <br />
                    <div className='text-lg font-semibold' id="1">1. Introduction</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>1.1</div>
                            <div>We are committed to safeguarding the privacy of our website visitors and service users.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>1.2</div>
                                <div>This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>1.3</div>
                                <div>We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="2">2. Interpretation</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>2.1</div>
                            <div>In this policy, "we", "us" and "our" refer to Kapybara.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>2.2</div>
                                <div>This policy applies to personal data collected through our website.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>2.3</div>
                                <div>This policy does not apply to personal data collected through third party websites.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="3">3. Information We Collect</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>3.1</div>
                            <div>We may collect, store and use the following kinds of personal data:</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths);</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(b)</div>
                                <div>information that you provide to us when registering with our website (including your email address);</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(c)</div>
                                <div>information that you provide when completing your profile on our website (including your name, profile pictures);</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="4">4. How We Use Your Information</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>4.1</div>
                            <div>Personal data submitted on this website will be used for the purposes specified in this privacy policy or in relevant parts of the website.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>4.2</div>
                                <div>We may use your personal information to:</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>administer our website and business;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(b)</div>
                                <div>personalize our website for you;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(c)</div>
                                <div>enable your use of the services available on our website;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(d)</div>
                                <div>send you email notifications that you have specifically requested;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(e)</div>
                                <div>send you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter);</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(f)</div>
                                <div>send you marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications);</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(g)</div>
                                <div>provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information);</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(h)</div>
                                <div>deal with inquiries and complaints made by or about you relating to our website;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(i)</div>
                                <div>keep our website secure and prevent fraud;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(j)</div>
                                <div>verify compliance with the terms and conditions governing the use of our website;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(k)</div>
                                <div>other uses.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="5">5. Disclosure Of Your Information</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>5.1</div>
                            <div>We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this policy.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>5.2</div>
                                <div>We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes set out in this policy.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>5.3</div>
                                <div>We may disclose your personal information:</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>to the extent that we are required to do so by law;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(b)</div>
                                <div>in connection with any ongoing or prospective legal proceedings;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(c)</div>
                                <div>in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(d)</div>
                                <div>to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling; and</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(e)</div>
                                <div>to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>5.4</div>
                                <div>Except as provided in this policy, we will not provide your personal information to third parties.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="6">6. Your Rights</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>6.1</div>
                            <div>You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to:</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>the payment of a fee (currently fixed at USD 10); and</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(b)</div>
                                <div>the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy of your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current address).</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>6.2</div>
                                <div>We may withhold personal information that you request to the extent permitted by law.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>6.3</div>
                                <div>You may instruct us at any time not to process your personal information for marketing purposes.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>6.4</div>
                                <div>In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="7">7. Third Party Links</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>7.1</div>
                            <div>Our website includes hyperlinks to, and details of, third party websites.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>7.2</div>
                                <div>We have no control over, and are not responsible for, the privacy policies and practices of third parties.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="8">8. Use Of Cookies</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>8.1</div>
                            <div>Our website uses cookies.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.2</div>
                                <div>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.3</div>
                                <div>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.4</div>
                                <div>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.5</div>
                                <div>We use both session and persistent cookies on our website.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.6</div>
                                <div>The names of the cookies that we use on our website, and the purposes for which they are used, are set out below:</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>we use Google Analytics and AdSense on our website to recognize a computer when a user visits the website / track users as they navigate the website / improve the website's usability /
                                    analyze the use of the website / administer the website / prevent fraud and improve the security of the website / personalize the website for each user / target advertisements which may be of particular interest to specific users;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.7</div>
                                <div>Most browsers allow you to refuse to accept cookies; for example:</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>in Internet Explorer (version 11) you can block cookies using the cookie handling override settings available by clicking "Tools", "Internet Options", "Privacy" and then "Advanced";</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(b)</div>
                                <div>in Firefox (version 47) you can block all cookies by clicking "Tools", "Options", "Privacy", selecting "Use custom settings for history" from the drop-down menu, and unticking "Accept cookies from sites"; and</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(c)</div>
                                <div>in Chrome (version 52), you can block all cookies by accessing the "Customize and control" menu, and clicking "Settings", "Show advanced settings" and "Content settings", and then selecting "Block sites from setting any data" under the "Cookies" heading.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.8</div>
                                <div>Blocking all cookies will have a negative impact upon the usability of many websites.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>8.9</div>
                                <div>If you block cookies, you will not be able to use all the features on our website.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="9">9. Security</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>9.1</div>
                            <div>We will take reasonable technical and organizational precautions to prevent the loss, misuse or alteration of your personal information.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>9.2</div>
                                <div>We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>9.3</div>
                                <div>All electronic financial transactions entered into through our website will be protected by encryption technology.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>9.4</div>
                                <div>You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>9.5</div>
                                <div>You are responsible for keeping the password you use for accessing our website confidential; we will not ask you for your password (except when you log in to our website).</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='text-lg font-semibold' id="10">10. Data Retention</div>
                    <div>
                        <div className='flex flex-row gap-1'>
                            <div>10.1</div>
                            <div>This Section sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal information.</div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.2</div>
                                <div>Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.3</div>
                                <div>Notwithstanding the other provisions of this Section, we will retain documents (including electronic documents) containing personal data:</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(a)</div>
                                <div>to the extent that we are required to do so by law;</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(b)</div>
                                <div>if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1 ml-4'>
                                <div>(c)</div>
                                <div>in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.4</div>
                                <div>We will take reasonable steps to ensure that your data is treated securely and in accordance with this privacy policy.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.5</div>
                                <div>We will not transfer your personal data to countries outside the European Economic Area.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.6</div>
                                <div>You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.7</div>
                                <div>You should be aware that any information you provide in these areas may be read, collected, and used by others who access them.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.8</div>
                                <div>We may update this policy from time to time by publishing a new version on our website.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.9</div>
                                <div>You should check this page occasionally to ensure you are happy with any changes to this policy.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.10</div>
                                <div>We may notify you of changes to this policy by email.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.11</div>
                                <div>Our website includes hyperlinks to, and details of, third party websites.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.12</div>
                                <div>We have no control over, and are not responsible for, the privacy policies and practices of third parties.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.13</div>
                                <div>Please let us know if the personal information that we hold about you needs to be corrected or updated.</div>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-row gap-1'>
                                <div>10.14</div>
                                <div>If you have any questions about this privacy policy or our treatment of your personal data, please write to us by email to&nbsp;
                                    <a href="mailto:ask@kapybara.app">ask@kapybara.app</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer bottom />
    </div>;
}