import Footer from '../components/Footer';
import Header from '../components/Header';

export function TermsOfService() {
    return <div className='main'>
        <Header active='legal' />
        <div className='h-12'></div>
        <div className='p-16'>
            <div className='font-bold text-2xl'>Terms of Service</div>
            <div className='text-slate-400'>Please read the below policy carefully</div>

            <div className='h-4'></div>
            <div
                className='rounded-md border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 flex justify-start p-4 overflow-y-auto'
                style={{ maxHeight: '58vh' }}
            >
                <div className="text-justify">
                    <div className="text-lg font-semibold">Terms of Service</div>
                    <div>Last updated: 11<sup>st</sup> November 2024</div>
                    <br />

                    <div className="text-lg font-semibold">Table Of Contents</div>
                    <div><a href="#1">1. Introduction</a></div>
                    <div><a href="#2">2. Interpretation</a></div>
                    <div><a href="#3">3. Referral Commission</a></div>
                    <div><a href="#4">4. Termination of Referral Commissions</a></div>
                    <div><a href="#5">5. Use of the Service</a></div>
                    <div><a href="#6">6. Limitation of Liability</a></div>
                    <div><a href="#7">7. Governing Law</a></div>
                    <div><a href="#8">8. Contact Us</a></div>
                    <br />

                    <div className="text-lg font-semibold" id="1">1. Introduction</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>1.1</div>
                            <div>These Terms of Service (“Terms”) govern the use of our software and services (“Service”) provided by Kapybara (“we,” “us,” or “our”) by users and referring parties (“you,” “your,” or “user”).</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>1.2</div>
                            <div>By accessing or using our Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you must not use our Service.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>1.3</div>
                            <div>We reserve the right to amend these Terms from time to time. Any amendments will be posted on our website, and your continued use of the Service will constitute acceptance of such amendments.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="2">2. Interpretation</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>2.1</div>
                            <div>In these Terms, “referrer” refers to any user who promotes our Service to third parties and becomes eligible to receive commission on subscriptions resulting from their referral.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>2.2</div>
                            <div>The term “subscription” refers to any recurring paid access to our Service by a third party as a result of a referral by the referrer.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>2.3</div>
                            <div>"Commission" refers to the amount earned by the referrer, calculated as a percentage of the subscription fee paid by the referred party.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="3">3. Referral Commission</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>3.1</div>
                            <div>Referrers are eligible to earn a commission of 20% on each subscription purchased by a third party they have referred to our Service.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>3.2</div>
                            <div>The commission is calculated based on the subscription payments actually received from the referred party, excluding any taxes, fees, or other deductions.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>3.3</div>
                            <div>The commission will be disbursed to the referrer periodically as determined by us and according to our payout procedures.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="4">4. Termination of Referral Commissions</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>4.1</div>
                            <div>In the event that the referred party cancels or discontinues their subscription, the referrer will immediately cease to be eligible to earn commission on that subscription.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>4.2</div>
                            <div>We reserve the right to terminate or withhold commission payments if we suspect fraudulent, abusive, or otherwise inappropriate referral activity, as determined at our sole discretion.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>4.3</div>
                            <div>These Terms do not create any employment, partnership, agency, or other relationship between Kapybara and the referrer. The referrer is solely responsible for any tax obligations arising from the commission payments.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="5">5. Use of the Service</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>5.1</div>
                            <div>Users are prohibited from using the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>5.2</div>
                            <div>Users must not use the Service for any unlawful, illegal, fraudulent, or harmful purpose or activity.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>5.3</div>
                            <div>We reserve the right to terminate or restrict any user’s access to the Service without notice if they are found to be in violation of these Terms.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="6">6. Limitation of Liability</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>6.1</div>
                            <div>Our Service is provided “as is,” and we do not warrant that it will be uninterrupted, error-free, or free from harmful components.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>6.2</div>
                            <div>To the maximum extent permitted by law, we are not liable for any damages or losses, whether direct, indirect, incidental, consequential, or otherwise, arising from your use of or inability to use our Service.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>6.3</div>
                            <div>You acknowledge and agree that your sole and exclusive remedy for any dispute with us related to your use of the Service is to discontinue your use of the Service.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="7">7. Governing Law</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>7.1</div>
                            <div>These Terms shall be governed by and construed in accordance with the laws of Malaysia.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>7.2</div>
                            <div>Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Malaysia.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="8">8. Contact Us</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>8.1</div>
                            <div>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:ask@kapybara.app">ask@kapybara.app</a>.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer bottom />
    </div>;
}