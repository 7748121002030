import Header from '../components/Header';
import Footer from '../components/Footer';

export function RefundPolicy() {
    return <div className='main'>
        <Header active='legal' />
        <div className='h-12'></div>
        <div className='p-16'>
            <div className='font-bold text-2xl'>Refund Policy</div>
            <div className='text-slate-400'>Please read the below policy carefully</div>

            <div className='h-4'></div>
            <div
                className='rounded-md border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 flex justify-start p-4 overflow-y-auto'
                style={{ maxHeight: '58vh' }}
            >
                <div className="text-justify">
                    <div className="text-lg font-semibold">Refund Policy</div>
                    <div>Last updated: 17<sup>th</sup> February 2025</div>
                    <br />

                    <div className="text-lg font-semibold">Table Of Contents</div>
                    <div><a href="#1">1. Eligibility for Refund</a></div>
                    <div><a href="#2">2. Non-Refundable Items</a></div>
                    <div><a href="#3">3. How to Request a Refund</a></div>
                    <div><a href="#4">4. Refund Processing</a></div>
                    <div><a href="#5">5. Contact Information</a></div>
                    <br />

                    <div className="text-lg font-semibold" id="1">1. Eligibility for Refund</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>1.1</div>
                            <div>The request is made within <strong>7</strong> days of the purchase date.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>1.2</div>
                            <div>The product/service is unused, undamaged, and in its original condition (if applicable).</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>1.3</div>
                            <div>The issue qualifies under our refund policy (e.g., defective product, service failure).</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>1.4</div>
                            <div>Proof of purchase (e.g., receipt or order number) must be provided.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="2">2. Non-Refundable Items</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>-</div>
                            <div>Digital downloads or software licenses.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>-</div>
                            <div>Custom or personalized services/products.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>-</div>
                            <div>Subscription-based services that have already been used within the billing period.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="3">3. How to Request a Refund</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>3.1</div>
                            <div>Contact our support team at <strong>kent@nixsoft.co</strong> with your order details.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>3.2</div>
                            <div>Provide proof of purchase and a reason for the refund request.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>3.3</div>
                            <div>We will review your request within <strong>7</strong> business days and notify you of the approval or rejection.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="4">4. Refund Processing</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>4.1</div>
                            <div>If approved, refunds will be processed to the original payment method within <strong>14</strong> business days.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>4.2</div>
                            <div>Customers are responsible for any transaction fees or bank charges associated with the refund.</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>4.3</div>
                            <div>If a refund is delayed, please check with your bank or payment provider before contacting us.</div>
                        </div>
                    </div>
                    <br />

                    <div className="text-lg font-semibold" id="5">5. Contact Information</div>
                    <div>
                        <div className="flex flex-row gap-1">
                            <div>5.1</div>
                            <div>If you have any questions about our refund policy, please contact us at:</div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>📧 Email: <strong>kent@nixsoft.co</strong></div>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div>📞 Phone: <strong>+6011-1150 1103</strong></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer bottom />
    </div>;
}